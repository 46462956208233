import { IStore, IGoodWriteOffCreateDto, IGoodWriteOff, IGood, IGoodWriteOffInfo, ISelectedData } from "@lib";
import { Control, Icon, Label, NumberBox, Select, TextArea } from "@core/components/alt-ui/controls";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Footer } from "@core/controls/footer.control";
import { Table, TableColumn, TableFooter } from "@core/components/alt-ui/table";
import { SearchGoods } from "@/core/alt-forms/components/search-goods/search-goods";
import { Uuid } from "@/utils/uuid";
import { AltMessage } from "@/utils/plugins/alt-message";
import { AltToast } from "@/utils/plugins/alt-toast";
import { moneyFormat } from "@/filters/money";
import { WriteOffItemsMaxCount } from "./good-write-offs-defaults";

export interface IGoodWriteOffModalContext {
    userId: string;
    stores: IStore[];
    storeId: string;
    message: AltMessage;
    toast: AltToast;
    goodWriteOff?: IGoodWriteOff;
    fromInventory?: IGoodWriteOffModalFromInventory;

    /** Обработчик поиска товаров. */
    searchGoodsHandler?: (search: string, storeId: string) => Promise<ISelectedData<IGood>>;

    /** Обработчик создания списания. */
    createHandler?: (dto: IGoodWriteOffCreateDto) => Promise<IGoodWriteOff | null>;
}

export interface IGoodWriteOffModalFromInventory {
    inventoryId: string;
    storeId: string;
    items: IGoodWriteOffTableItem[];
    comment?: string;
}

export interface IGoodWriteOffTableItem {
    goodId: string;
    name: string;
    cost: number;
    /** Количество на складе. */
    quantity: number;
    /** Списываемое количество. */
    writtenOffQuantity: number;
}

export class GoodWriteOffModal extends Modal2<IGoodWriteOffModalContext, IGoodWriteOff | null> {
    private cbStore!: Select<IStore>;
    private ctSearchGoods!: SearchGoods;
    private tblGoodsTable!: Table<IGoodWriteOffTableItem>;
    private taComment!: TextArea;
    private ftFooter!: Footer;

    private context!: IGoodWriteOffModalContext;

    public constructor(id = "good-writeoff-modal") {
        super(id, "");

        this.size = "lg";
        this.scrollable = false;
        this.initializeControls();
    }

    public get footer(): Footer {
        return this.ftFooter;
    }

    public show(context: IGoodWriteOffModalContext): Promise<IGoodWriteOff | null> {
        this.context = context;
        this.title = "Списание";

        this.initializeControls();

        if (this.context.goodWriteOff) {
            this.populateControls(this.context.goodWriteOff);
        }

        if (this.context.fromInventory) {
            this.populateControlsFromInventory(this.context.fromInventory);
        }

        return super.show();
    }

    private getControlId(controlId: string): string {
        return `good-write-off-modal.${controlId}`;
    }

    protected initializeControls(): void {
        this.cbStore = new Select<IStore>();
        this.tblGoodsTable = new Table<IGoodWriteOffTableItem>();
        this.taComment = new TextArea();

        this.cbStore.id = "good.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedItem =
            this.cbStore.items.find(o => o.id === this.context?.storeId) ??
            (this.cbStore.items.length > 0 ? this.cbStore.items[0] : null);

        //

        this.ctSearchGoods = new SearchGoods();
        this.ctSearchGoods.id = this.getControlId("search-goods");
        this.ctSearchGoods.label = "Введите название товара";
        this.ctSearchGoods.selectSingle = true;
        this.ctSearchGoods.searchFunc = search => this.searchGoods(search);
        this.ctSearchGoods.addSelectItemHandler((s, e) => {
            if (!e.item) {
                return;
            }

            this.addItem(e.item, 1);
        });

        //

        this.tblGoodsTable.id = this.getControlId("goodsTable");
        this.tblGoodsTable.class = "mt-2";
        this.tblGoodsTable.columns = this.getTableColumns(false);
        this.tblGoodsTable.footer = this.getTableFooter();

        //

        this.taComment.id = this.getControlId("comment");
        this.taComment.label = "Комментарий";

        this.ctSearchGoods.visible = true;
        this.cbStore.disabled = false;
        this.taComment.disabled = false;

        this.ftFooter = new Footer({
            okText: this.context?.goodWriteOff ? "" : "Списать",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(goodWriteOff: IGoodWriteOff): void {
        this.cbStore.selectedItem = this.cbStore.items.find(s => s.id === goodWriteOff.store) ?? null;
        this.cbStore.disabled = true;

        this.ctSearchGoods.visible = false;

        this.tblGoodsTable.items = goodWriteOff.goods
            .filter(g => !!g.goodRef)
            .map(g => {
                const good = g.goodRef as IGood;

                return {
                    goodId: good.id,
                    name: good.info.name,
                    cost: good.info.cost,
                    quantity: good.info.quantity,
                    writtenOffQuantity: g.writedOffQuantity,
                };
            });

        this.tblGoodsTable.columns = this.getTableColumns(true);

        this.taComment.text = goodWriteOff.comment ?? "";
        this.taComment.disabled = true;
    }

    private populateControlsFromInventory(fromInventory: IGoodWriteOffModalFromInventory): void {
        this.cbStore.selectedItem = this.cbStore.items.find(s => s.id === fromInventory.storeId) ?? null;
        this.cbStore.disabled = true;

        this.ctSearchGoods.visible = false;

        this.tblGoodsTable.items = fromInventory.items.map(i => ({
            goodId: i.goodId,
            name: i.name,
            cost: i.cost,
            quantity: i.quantity,
            writtenOffQuantity: i.writtenOffQuantity,
        }));

        this.tblGoodsTable.columns = this.getTableColumns(false);

        this.taComment.text = fromInventory.comment ?? "";
    }

    private getTableColumns(readonly: boolean): TableColumn<IGoodWriteOffTableItem>[] {
        const columns: TableColumn<IGoodWriteOffTableItem>[] = [
            {
                title: "Наименование",
                width: 50,
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => item.name,
            },
            {
                title: "На складе",
                classHeader: "text-xs",
                width: 20,
                cell: item => item.quantity.toString(),
            },
            {
                title: "Количество",
                classHeader: "text-xs",
                width: 20,
                cell: item => {
                    if (readonly) {
                        const lbl = new Label();
                        lbl.id = Uuid.new();
                        lbl.class = "text-base";
                        lbl.text = item.writtenOffQuantity.toString();
                        return lbl;
                    }

                    const number = new NumberBox();
                    number.id = Uuid.new();
                    number.max = item.quantity;
                    number.value = item.writtenOffQuantity;
                    number.disabled = readonly;
                    number.addValueChangedHandler((s, e) => {
                        item.writtenOffQuantity = e.value ?? 0;
                    });

                    return number;
                },
            },
        ];

        if (!readonly) {
            columns.unshift({
                width: 1,
                cell: (item, index) => {
                    const iconDelete = new Icon();
                    iconDelete.icon = "Trash2Icon";
                    iconDelete.class = "cursor-pointer hover:text-danger";
                    iconDelete.addClickHandler(async (s, e) => {
                        const confirm = await this.context.message.confirm(
                            `Вы уверены, что хотите удалить товар "${item.name}"?`,
                            "Удаление товара",
                            { acceptText: "Удалить" },
                        );

                        if (confirm) {
                            this.tblGoodsTable.items = this.tblGoodsTable.items.filter((_, i) => i !== index);
                        }
                    });
                    return iconDelete;
                },
            });
        }

        return columns;
    }

    private getTableFooter(): TableFooter<IGoodWriteOffTableItem>[] {
        return [
            {
                title: "Наименований:",
                cell: items => {
                    let str = items.length.toString();

                    if (WriteOffItemsMaxCount - items.length < 5) {
                        str += ` (макс. ${WriteOffItemsMaxCount})`;
                    }

                    return str;
                },
            },
            {
                title: "Сумма:",
                cell: items => {
                    const locale = this.cbStore.selectedItem?.info.locale;
                    const value = items.reduce((sum, item) => (sum += item.cost * item.writtenOffQuantity), 0);
                    return moneyFormat(value, { locale });
                },
            },
        ];
    }

    private searchGoods(search: string): Promise<ISelectedData<IGood>> {
        if (!this.context || !this.context.searchGoodsHandler) {
            return Promise.resolve({ data: [], total: 0 });
        }

        const storeId = this.cbStore.selectedItem?.id ?? this.context.storeId;
        return this.context.searchGoodsHandler(search, storeId);
    }

    private addItem(good: IGood, quantity: number): void {
        const existed = this.tblGoodsTable.items.find(i => i.goodId === good.id);

        if (existed) {
            if (existed.writtenOffQuantity + quantity > good.info.quantity) {
                this.context.toast.warning("Нельзя списать больше, чем есть на складе");
                return;
            }

            existed.writtenOffQuantity += quantity;
        } else {
            if (this.tblGoodsTable.items.length >= WriteOffItemsMaxCount) {
                this.context.toast.warning(`Нельзя добавить больше ${WriteOffItemsMaxCount} наименований`);
                return;
            }

            this.tblGoodsTable.items.push({
                goodId: good.id,
                name: good.info.name,
                cost: good.info.cost,
                quantity: good.info.quantity,
                writtenOffQuantity: quantity,
            });
        }
    }

    public get controls(): Control[] {
        return [this.cbStore, this.ctSearchGoods, this.tblGoodsTable, this.taComment];
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(null);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.tblGoodsTable.items.length) {
            return;
        }

        const writeoff = await this.create();

        if (writeoff) {
            this.hide(writeoff);
        }
    }

    private async create(): Promise<IGoodWriteOff | null> {
        if (!this.context?.createHandler || !this.cbStore.selectedItem) {
            return null;
        }

        const amount = this.tblGoodsTable.items.reduce((sum, item) => (sum += item.cost * item.writtenOffQuantity), 0);

        const goods = this.tblGoodsTable.items.map(item => {
            const good: IGoodWriteOffInfo = {
                id: item.goodId,
                cost: item.cost,
                quantity: item.quantity,
                writedOffQuantity: item.writtenOffQuantity,
            };

            return good;
        });

        const dto: IGoodWriteOffCreateDto = {
            goods: goods as IGoodWriteOffInfo[],
            storeId: this.cbStore.selectedItem.id,
            employeeId: this.context.userId,
            amount,
            comment: this.taComment.text,
        };

        if (this.context.fromInventory) {
            dto.meta = {
                inventory: this.context.fromInventory.inventoryId,
            };
        }

        return await this.context.createHandler(dto);
    }
}
